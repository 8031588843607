import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import SlackLogo from 'images/slack_logo.png';
import 'react-table/react-table.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddEndpointPane from '../../../components/pane/AddEndpointPane';
import { getEndpoints, getEndpointsCsv, removeEndpoint } from '../../../services/webhook';
import { toast } from 'react-toastify';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';
import 'react-toastify/dist/ReactToastify.css';
import AboutPageDrawer from '../../AboutDrawers/WebhooksSettingsPage/AboutPageDrawer';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;
const NATIVE_WEBHOOK_INTEGRATION_TYPE = 'native_webhook';
const SLACK_INTEGRATION_TYPE = 'slack';

toast.configure();

const accordionAriaExpanded = (integrationName, openAccordionKey) =>
  integrationName === openAccordionKey ? 'true' : 'false';

const accordionButtonClass = (integrationName, openAccordionKey) =>
  integrationName === openAccordionKey
    ? 'accordion-button'
    : 'accordion-button collapsed';

const accordionBodyParentClass = (integrationName, openAccordionKey) =>
  integrationName === openAccordionKey
    ? 'accordion-collapse collapse show'
    : 'accordion-collapse collapse';

const handleOpenAccordionKeySetState = (integrationName, openAccordionKey) =>
  integrationName === openAccordionKey ? '' : integrationName;

const integrationLogo = {
  'slack': SlackLogo,
};

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.webhookEndpoints.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedEndpoint: {},
      search: '',
      endpoints: [], // props.webhookEndpoints.entries,
      endpointsCount: 0, // props.webhookEndpoints.total_count,
      isAboutPageDrawerOpen: false,
      openAccordionKey: '',
    };
    this.handleOnClickAddEndpoint = this.handleOnClickAddEndpoint.bind(this);
    this.onAddEndpointPaneClose = this.onAddEndpointPaneClose.bind(this);
    this.handleOnClickEditEndpoint = this.handleOnClickEditEndpoint.bind(this);
    this.handleWebhookEndpointsSearch = this.handleWebhookEndpointsSearch.bind(this);
    this.handleEndpointsFilterFetch = this.handleEndpointsFilterFetch.bind(this);
    this.handleGetPaginatedEndpoints = this.handleGetPaginatedEndpoints.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOnClickEditEndpoint = this.handleOnClickEditEndpoint.bind(this);
    this.handleOnClickOpenAboutPageDrawer = this.handleOnClickOpenAboutPageDrawer.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  handleOnClickOpenAboutPageDrawer = () => {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose = () => {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleWebhookEndpointsSearch = event => {
    const input = event.target.value
    this.handleEndpointsFilterFetch(input);
  }

  handleEndpointsFilterFetch = async input => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const filteredEndpoints = await getEndpoints({ search: input, organization_id: currentUser?.organization_id });
    if (filteredEndpoints) {
      this.setState({ endpoints: filteredEndpoints.entries || [], search: input });
    }
  }

  handleGetPaginatedEndpoints = async (page, pageSize) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const { search } = this.state;
    const endpoints = await getEndpoints({ page: page + 1, page_size: pageSize, search, organization_id: currentUser?.organization_id });
    endpoints
      ? this.setState({ endpoints: endpoints.entries, pages: endpoints.total_pages, endpointsCount: endpoints.total_count, loading: false })
      : this.setState({ endpoints: [], pages: 0, loading: false });
  }

  handleOnClickAddEndpoint = () => {
    this.setState({ isPaneOpen: true, isEditMode: false });
  }

  onAddEndpointPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditEndpoint = endpoint => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedEndpoint: endpoint });
  }

  handleConfirmDelete = async (endpoint, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveEndpoint}
            subtitle="You want to delete this webhook endpoint?"
            itemToRemove={endpoint}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveEndpoint = async (endpoint, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeEndpoint({
      organization_id: currentUser?.organization_id,
      id: endpoint.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedEndpoints(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('Endpoint removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the endpoint. Please try again.', toastifyConfiguration({}));
    }
  }

  render() {
    const { currentUser } = this.props;
    const {
      openAccordionKey
    } = this.state;

    const integrations = [
      {
        name: 'Salesforce',
        status: 'connected',
        description: 'Salesforce description',
        connection_type: 'bi-directional',
      },
      {
        name: 'Zendesk',
        status: 'not connected',
        description: 'Zendesk description',
        connection_type: 'bi-directional',
      },
      {
        name: 'Jira',
        status: 'not connected',
        description: 'Jira description',
        connection_type: 'outbound',
      },
      {
        name: 'Slack',
        status: 'not connected',
        description: 'Slack description',
        connection_type: 'outbound',
      },
      {
        name: 'Microsoft Teams',
        status: 'not connected',
        description: 'Microsoft Teams description',
        connection_type: 'outbound',
      }
    ];

    return (
      <div className="settings webhook-endpoints container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Connections</div>
          <div className="subheader">
            <div className="page">Integrations</div>
            <div
              className="pro-tip-button"
              onClick={() => this.handleOnClickOpenAboutPageDrawer()}
            >
              Pro Tip
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="container-fluid">
            <div className="accordion" id="accordionForIntegrations">
              {integrations.map(integration => (
                <div className="accordion-item" key={integration.name}>
                  <h2 className="accordion-header">
                    <button
                      className={accordionButtonClass(integration.name, openAccordionKey)}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${integration.name}`}
                      aria-expanded={accordionAriaExpanded(integration.name, openAccordionKey)}
                      aria-controls={`#collapse-${integration.name}`}
                      onClick={() =>
                        this.setState({
                          openAccordionKey: handleOpenAccordionKeySetState(
                            integration.name, openAccordionKey,
                          )
                        }
                      )}
                    >
                      <div className="container">
                        <div className="row row-cols-2">
                          <div className="col-6 d-flex">
                            <img
                              height={20}
                              width={20}
                              className="me-2"
                              src={integrationLogo[integration.name?.toLowerCase()]}
                              alt={`${integration.name} Logo`}
                            />
                            <div>{integration.name}</div>
                          </div>
                          <div className="col-6">{integration.status}</div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div id={`#collapse-${integration.name}`} className={accordionBodyParentClass(integration.name, openAccordionKey)} data-bs-parent="#accordionForIntegrations">
                    <div className="accordion-body">
                      <div className="container">
                        <div className="row row-cols-2 mb-4">
                          <div className="col-6">Desciption</div>
                          <div className="col-6"></div>
                        </div>
                        <div className="row row-cols-2">
                          <div className="col-6">{integration.description}</div>
                          <div className="col-6">
                            {integration.status === 'connected' ? 'Disconnect' : 'Connect' }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/*
              <SlidingPane
                className='add-endpoint-pane'
                overlayClassName='sliding-pane-overlay'
                isOpen={ this.state.isPaneOpen }
                title={isEditMode
                  ? 'Edit Endpoint - Webhook Integrations'
                  : 'Add New Endpoint - Webhook Integrations'
                }
                width='60%'
                subtitle=''
                onRequestClose={this.onAddEndpointPaneClose}>
                  <AddEndpointPane
                    handleGetPaginatedEndpoints={this.handleGetPaginatedEndpoints}
                    currentUser={currentUser}
                    onAddEndpointPaneClose={this.onAddEndpointPaneClose}
                    isEditMode={isEditMode}
                    selectedEndpoint={selectedEndpoint}
                  />
              </SlidingPane>
            */}
          </div>
        </div>
        <SlidingPane
          className='about-page-drawer settings webhooks-list'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutPageDrawer currentUser={currentUser} />
        </SlidingPane>
      </div>
    );
  }
}

Content.propTypes = {
};

export default Content;
