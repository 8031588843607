import React, { Fragment } from 'react';
import PropTypes from 'prop-types';


class ConfirmSubmitMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      handleSubmitItem,
      onClose,
      itemToSubmit,
      itemsToSubmit,
      isMultipleSubmission,
      event,
      subtitle,
      confirmButtonText,
    } = this.props;
    return (
      <div className="confirm-modal confirm-submit-message custom-ui">
        <div className="mb-5">
          <i className="bi bi-check-circle"></i>
        </div>
        <div className="header mb-4">
          Are you sure?
        </div>
        {subtitle && (
          <div className="subtitle-container d-flex justify-content-center text-align-center mb-5">
            <div className="subtitle">
              {subtitle}
            </div>
          </div>
        )}
        <div className="buttons-container d-flex flex-column align-items-center justify-content-center">
          <button
            type="button"
            className="btn py-3 mb-4 confirm-submit-button"
            onClick={() => {
              if (isMultipleSubmission) {
                handleSubmitItem(itemsToSubmit, event)
              } else {
                handleSubmitItem(itemToSubmit, event);
              }
              onClose();
            }}
          >
            {confirmButtonText}
          </button>
          <button
            type="button"
            className="btn py-3 close-button"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    );
  }
}

ConfirmSubmitMessage.propTypes = {
  handleSubmitItem: PropTypes.func,
  itemToSubmit: PropTypes.shape({}),
  itemsToSubmit: PropTypes.arrayOf(PropTypes.string),
  isMultipleSubmission: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  event: PropTypes.shape({}),
  onClose: PropTypes.func,
};

ConfirmSubmitMessage.defaultProps = {
 confirmButtonText: 'Yes',
 isMultipleSubmission: false,
};

export default ConfirmSubmitMessage;
