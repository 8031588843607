import { paramsToQueryString } from '../services/util/helpers';

export async function getWorkspaces({
  search,
  page_size,
  page,
  organization_id
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organization_id}/workspaces.json`
    : `/organizations/${organization_id}/workspaces.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getWorkspacesCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/workspaces/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/workspaces.csv`;
  });
  return;
}

export async function getWorkspaceCsv({
  id,
  workspace_name,
  organization_id,
}) {
  const url = `/organizations/${organization_id}/workspaces/${id}/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/workspaces/${id}.csv?workspace_name=${workspace_name}`;
  });
  return;
}

export async function getWorkspaceAccessRequestsCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/workspace_access_requests/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/workspace_access_requests.csv`;
  });
  return;
}

export async function upsertWorkspace({
  organization_id,
  name,
  cleverSchoolId,
  description,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      name,
      clever_school_id: cleverSchoolId,
      description,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertWorkspaceMembers({
  organization_id,
  workspace_id,
  emails,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces/${workspace_id}/users`, {
    method: 'post',
    body: JSON.stringify({
      emails,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateWorkspaceMember({
  organization_id,
  workspace_id,
  workspace_member_id,
  roles,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces/${workspace_id}/users/${workspace_member_id}`, {
    method: 'put',
    body: JSON.stringify({ roles }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertWorkspaceAccessRequests({
  organization_id,
  request_ids,
  approved,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspace_access_requests/update_multiple`, {
    method: 'put',
    body: JSON.stringify({
      organization_id,
      request_ids,
      approved,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeWorkspaceMember({
  organization_id,
  workspace_id,
  workspace_member_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces/${workspace_id}/users/${workspace_member_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateWorkspace({
  organization_id,
  workspace_id,
  cleverSchoolId,
  description,
  name,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces/${workspace_id}`, {
    method: 'put',
    body: JSON.stringify({
      name,
      clever_school_id: cleverSchoolId,
      description,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeWorkspace({
  organization_id,
  workspace_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspaces/${workspace_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeWorkspaceAccessRequest({
  organization_id,
  workspace_access_request_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/workspace_access_requests/${workspace_access_request_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
