import { paramsToQueryString } from '../services/util/helpers';

export async function getTeams({
  search,
  page_size,
  page,
  organization_id
}) {
  const params = { search, page, page_size };
  const url = !paramsToQueryString(params)
    ? `/organizations/${organization_id}/teams.json`
    : `/organizations/${organization_id}/teams.json?${paramsToQueryString(params)}`

  const response = await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function getTeamsCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/teams/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/teams.csv`;
  });
  return;
}

export async function getTeamCsv({
  id,
  team_name,
  organization_id,
}) {
  const url = `/organizations/${organization_id}/teams/${id}/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/teams/${id}.csv?team_name=${team_name}`;
  });
  return;
}

export async function getTeamAccessRequestsCsv({
  organization_id,
}) {
  const url = `/organizations/${organization_id}/team_access_requests/download`

  await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    document.location.href = `/organizations/${organization_id}/team_access_requests.csv`;
  });
  return;
}

export async function upsertTeam({
  organization_id,
  name,
  description,
  owned_by,
  roles,
  tag_list,
  workspace_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams`, {
    method: 'post',
    body: JSON.stringify({
      organization_id,
      name,
      description,
      owned_by,
      roles,
      workspace_id,
      tag_list,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertTeamMembers({
  organization_id,
  team_id,
  emails,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/${team_id}/users`, {
    method: 'post',
    body: JSON.stringify({
      emails,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function upsertTeamAccessRequests({
  organization_id,
  request_ids,
  approved,
}) {
  const response = await fetch(`/organizations/${organization_id}/team_access_requests/update_multiple`, {
    method: 'put',
    body: JSON.stringify({
      organization_id,
      request_ids,
      approved,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateTeamMember({
  organization_id,
  team_id,
  roles,
  team_member_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/${team_id}/users/${team_member_id}`, {
    method: 'put',
    body: JSON.stringify({
      roles,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeTeamMember({
  organization_id,
  team_id,
  team_member_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/${team_id}/users/${team_member_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function updateTeam({
  organization_id,
  team_id,
  description,
  owned_by,
  name,
  roles,
  tag_list,
  workspace_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/${team_id}`, {
    method: 'put',
    body: JSON.stringify({
      name,
      description,
      owned_by,
      roles,
      workspace_id,
      tag_list,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeTeam({
  organization_id,
  team_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/${team_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeTeams({
  organization_id,
  team_ids,
}) {
  const response = await fetch(`/organizations/${organization_id}/teams/delete_all`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify({
      team_ids,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}

export async function removeTeamAccessRequest({
  organization_id,
  team_access_request_id,
}) {
  const response = await fetch(`/organizations/${organization_id}/team_access_requests/${team_access_request_id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  })
  .then((data) => {
    return data;
  });
  return response;
}
