export const statusOptions = ({ organizationPersona }) => {
  const options = [
    {
      label: 'Ready',
      value: 'ready'
    },
    {
      label: 'In Progress',
      value: 'in_progress',
    },
    {
      label: 'Testing',
      value: 'testing',
    },
    {
      label: 'Done',
      value: 'done',
    },
    {
      label: 'Done - Bandaid Solution',
      value: 'done_bandaid',
    },
    {
      label: 'Archived',
      value: 'archived',
    },
    {
      label: 'Canceled',
      value: 'canceled',
    },
  ];

  if (organizationPersona === 'education') {
    const omittedStatuses = ['testing', 'done_bandaid'];
    return options.filter(option => !omittedStatuses.includes(option.value));
  }
  return options
};

export const booleanOptions = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];

export const customerHealthStatusOptions = [
  {
    label: 'Healthy',
    value: 'healthy'
  },
  {
    label: 'Neutral',
    value: 'neutral',
  },
  {
    label: 'At Risk',
    value: 'at_risk',
  },
  {
    label: 'Uncategorized',
    value: 'uncategorized',
  },
];

export const FREE_TRIAL_VALUE = 'free_trial';
export const FREE_TRIAL_LABEL = 'Free Trial';

export const accountTypeOptions = [
  {
    label: 'Enterprise',
    value: 'enterprise',
  },
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Pro',
    value: 'pro',
  },
  {
    label: FREE_TRIAL_LABEL,
    value: FREE_TRIAL_VALUE,
  },
  {
    label: 'Education Standard',
    value: 'education_standard',
  },
  {
    label: 'Education Plus',
    value: 'education_plus',
  },
];

export const B2B_PERSONA_VALUE = 'b2b';
export const B2B_PERSONA_LABEL = 'B2B';

export const organizationPersonaOptions = [
  {
    label: B2B_PERSONA_LABEL,
    value: B2B_PERSONA_VALUE,
  },
  {
    label: 'Education',
    value: 'education',
  },
];

export const ACCOUNT_ADMIN_VALUE = 'account_admin';
export const ACCOUNT_ADMIN_LABEL = 'Account Admin';
export const ACCOUNT_USER_VALUE = 'account_user';
export const ACCOUNT_USER_LABEL = 'Account User';
export const WORKSPACE_MANAGER_VALUE = 'workspace_manager';
export const WORKSPACE_MANAGER_LABEL = 'Workspace Manager';
